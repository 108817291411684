import { API_URI } from '.'
import ArrayPayload from '../models/ArrayPayload'
import Entity from '../models/Entity'
import Service from '../models/Service'
import { getHeaders } from './auth'

export const getServices = async (): Promise<ArrayPayload<Service>> => {
	const response = await fetch(
		`${API_URI}/services` +
			`?populate=logo` +
			`&sort[0]=priority:desc` +
			`&sort[1]=publishedAt:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return response.json()
}

export const getService = async (urlPath: string): Promise<Entity<Service>> => {
	const response = await fetch(
		`${API_URI}/services?populate=logo&filters[urlPath][$eq]=${urlPath}`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	const json = await response.json()

	return json.data[0]
}
