import { PageProps } from 'gatsby'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAsync } from 'react-use'
import { getService } from '../../apiClients/services'
import Heading from '../../components/Heading'
import Layout from '../../components/Layout'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarkdownRenderer } from '../../components/MarkdownRenderer'
import Spinner from '../../components/Spinner'
import StoreItemHeader, {
	propsFromService,
} from '../../components/StoreItemHeader'
import { useAppContext } from '../../context'
import Breadcrumb from '../../models/Breadcrumb'
import Entity from '../../models/Entity'
import { SettingKey } from '../../models/GlobalSetting'
import Service from '../../models/Service'
import { buildMetaTitle } from '../../utils/meta'

export default function Services({ params, location }: PageProps) {
	const [service, setService] = useState<Entity<Service>>()
	const [loading, setLoading] = useState(true)
	const { getSettingValue } = useAppContext()

	useAsync(async () => {
		setLoading(true)
		const response = await getService(params.urlPath)
		if (response) setService(response)
		setLoading(false)
	}, [params.urlPath])

	const breadcrumbs: Breadcrumb[] = [
		{ link: '/services/', featureName: 'services' },
		{
			link: location.pathname,
			display: service?.attributes.title ?? params.urlPath,
		},
	]

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout breadcrumbs={breadcrumbs}>
			<Helmet>
				<title>{buildMetaTitle([service?.attributes.title, metaTitle])}</title>
			</Helmet>
			<LayoutContainer>
				{loading && !service ? (
					<Spinner />
				) : service ? (
					<>
						<MarkdownRenderer
							header={
								<StoreItemHeader {...propsFromService(service.attributes)} />
							}
							children={
								service?.attributes?.markdown ?? '# Service not available'
							}
						/>
					</>
				) : (
					<Heading>Service not found</Heading>
				)}
			</LayoutContainer>
		</Layout>
	)
}
